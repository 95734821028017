<template>
  <b-form-group :label="label" :label-for="inputId">
    <template v-if="textArea">
      <b-form-textarea :id="inputId" :value="value" :placeholder="placeholder" :disabled="disabled" max-rows="4" no-resize class="font-small-3" @input="updateValue" />
    </template>
    <template v-else>
      <b-form-input :id="inputId" :value="value" :placeholder="placeholder" :disabled="disabled" class="font-small-3" @input="updateValue" />
    </template>
  </b-form-group>
</template>

<script>

export default {
  name: 'OptionValue',
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    textArea: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
  },
  methods: {
    updateValue(newValue) {
        this.$emit('input', newValue);
    },
  },
};
</script>
