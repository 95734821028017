<template>
    <div class="letter"
         :class="isHovering(hoverLetter)"
         :style="computedStyle(settings.letter.style)"> <!---->

        <div class="letter-header"
             :class="isHovering(hoverHeader)"
             :style="computedStyle(settings.header.style)">

            <img :src="settings.logo.src" alt="Logo"
                 class="letter-logo"
                 :class="isHovering(hoverLogo)"
                 :style="computedStyle(settings.logo.style)">

            <div class="return-address"
                 :class="isHovering(hoverReturnAddress)"
                 :style="computedStyle(settings.returnAddress.style)">
                <div v-if="settings.returnAddress.text.line1" :contenteditable="editing" class="font-weight-bold">{{ settings.returnAddress.text.line1 }}</div>
                <div v-if="settings.returnAddress.text.line2" :contenteditable="editing">{{ settings.returnAddress.text.line2 }}</div>
                <div v-if="settings.returnAddress.text.line3" :contenteditable="editing">{{ settings.returnAddress.text.line3 }}</div>
                <div v-if="settings.returnAddress.text.line4" :contenteditable="editing">{{ settings.returnAddress.text.line4 }}</div>
                <div v-if="settings.returnAddress.text.line5" :contenteditable="editing">{{ settings.returnAddress.text.line5 }}</div>
            </div>
        </div>
        <div class="recipient-address"
             :class="isHovering(hoverRecipientAddress)"
             :style="computedStyle(settings.recipientAddress.style)">

          <!-- Teacher Name -->
          <template>
            <div v-if="hasValue(teacher, 'name')">{{ teacher.name.full }}</div>
            <div v-else class="text-danger">No Teacher</div>
          </template>

          <!-- Teacher School -->
          <template>
            <div v-if="hasValue(teacher, 'school.name')">{{ teacher.school.name.legal }}</div>
            <div v-else class="text-danger">No School</div>
          </template>

          <!-- Teacher Address -->
          <template>
            <template v-if="hasValue(teacher, 'address')">
              <div>
                <span>{{ teacher.address.line1 }}</span>
                <span v-if="teacher.address.line2">, {{ teacher.address.line2 }}</span>
              </div>
              <div>{{ teacher.address.city }}, {{ teacher.address.state }} {{ teacher.address.zip }}</div>
            </template>
            <template v-else>
              <div class="text-danger">No Address</div>
            </template>
          </template>

        </div>
        <div class="letter-body"
             :class="isHovering(hoverBody)"
             :style="computedStyle(settings.body.style)">
            <template v-if="editing">
                <tip-tap v-model="settings.body.text" :show-toolbar="true"
                         :toolbar-options="{
                                headings: false, fonts: true, alignment: false, list: true, code: false, link: false,
                                image: false, table: false,codeBlock: false, clear: true, more: false,
                                schools: false, icons: false, options: false
                         }"
                />
            </template>
            <template v-else>
                <div v-html="renderedText"/>
            </template>
        </div>
        <div v-if="showPageMarks || showFoldMarks" class="fold-guide">
          <div v-if="showPageMarks" class="fold-mark page-start"></div>
          <div v-if="showFoldMarks" class="fold-mark fold-mark-1"></div>
          <div v-if="showFoldMarks" class="fold-mark fold-mark-2"></div>
          <div v-if="showPageMarks" class="fold-mark page-end"></div>
        </div>
    </div>
</template>

<script>
import styleMixin from '@/mixins/style.mixin';
import TipTap from '@/components/TipTap.vue';
import lodashMixin from '@/mixins/lodash.mixin';

export default {
    name: 'FormattedLetter',
    components: {TipTap},
    mixins: [ styleMixin, lodashMixin ],
    props: {
      editing: {
          type: Boolean,
          default: false
      },
      settings: {
          type: Object,
          required: true
      },
      showFoldMarks: {
        type: Boolean,
        default: true
      },
      showPageMarks: {
        type: Boolean,
        default: true
      },
      teacher: {
            type: Object,
            default: () => ({
                name: {
                    first: '',
                    last: '',
                    full: ''
                },
                address: {
                    line1: '',
                    line2: '',
                    city: '',
                    state: '',
                    zip: ''
                },
                students: []
            })
        },
      hoverLetter: {
        type: Boolean,
        default: false,
      },
      hoverHeader: {
        type: Boolean,
        default: false,
      },
      hoverLogo: {
        type: Boolean,
        default: false,
      },
      hoverReturnAddress: {
        type: Boolean,
        default: false,
      },
      hoverRecipientAddress: {
        type: Boolean,
        default: false,
      },
      hoverBody: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
        renderedText() {
            let {text} = this.settings.body;
            const {format} = this.settings.students;

            // Replace {{ teacher.name.first }} and {{ teacher.name.last }}
            text = text.replace(/{{\s*teacher\.name\.first\s*}}/g, this.teacher?.name?.first);
            text = text.replace(/{{\s*teacher\.name\.last\s*}}/g, this.teacher?.name?.last);
            text = text.replace(/{{\s*teacher\.name\.full\s*}}/g, this.teacher?.name?.full);

            // Replace {{ teacher.address.line1 }}, {{ teacher.address.line2 }}, {{ teacher.address.city }}, {{ teacher.address.state }}, and {{ teacher.address.zip }}
            text = text.replace(/{{\s*teacher\.address\.line1\s*}}/g, this.teacher?.address?.line1);
            text = text.replace(/{{\s*teacher\.address\.line2\s*}}/g, this.teacher?.address?.line2);
            text = text.replace(/{{\s*teacher\.address\.city\s*}}/g, this.teacher?.address?.city);
            text = text.replace(/{{\s*teacher\.address\.state\s*}}/g, this.teacher?.address?.state);
            text = text.replace(/{{\s*teacher\.address\.zip\s*}}/g, this.teacher?.address?.zip);

            // Replace {{ teacher.students }}
            const studentsList = this.teacher.students.map(student => `<div class="letter-student">${this.formatStudentName(student, format)}</div>`).join('');
            text = text.replace(/{{\s*teacher\.students\s*}}/g, studentsList);

            // Wrap the studentsList with a div that has inline styles for multi-column layout
            const columnStyles = this.computedStyle(this.settings.students.style) || 'padding-top: 2rem; column-count: 1; column-gap: 20px;';
            const wrappedStudentsList = `<div style="${columnStyles} break-inside: avoid-page;">${studentsList}</div>`;
            text = text.replace(studentsList, wrappedStudentsList);

            return text;
        }
    },
    methods: {
      isHovering(prop) {
        return prop ? 'hover-border' : '';
      },
      formatStudentName(student, format) {
        let formattedName = '';
        switch (format) {
          case 'fn-ln':
              formattedName = `${student?.name?.first} ${student?.name?.last}`;
              break;
          case 'fn-ln-e':
              formattedName = `${student?.name?.first} ${student?.name?.last} - ${student?.ensemble?.name}`;
              break;
          case 'fn-ln-e-p':
            formattedName = `${student?.name?.first} ${student?.name?.last} - ${student?.ensemble?.name} ${student?.part ? ` - Part ${student?.part}` : ''}`;
            break;
          case 'fn-ln-e-i-p':
            formattedName = `${student?.name?.first} ${student?.name?.last} - ${student?.ensemble?.name} - ${student.instrument.name} ${student?.part ? ` - Part ${student?.part}` : ''}`; /*(${student?.school?.name?.legal})*/
            break;
          case 'fn-ln-i':
              formattedName = `${student?.name?.first} ${student?.name?.last} - ${student?.instrument?.name}`;
              break;
          default:
              formattedName = `${student?.name?.first} ${student?.name.last}`;
        }
        return formattedName;
      }
    }
};
</script>

<style scoped>
  .hover-border {
    background: rgba(33, 41, 105, 0.12) !important
  }
  .letter {
      width: 8.5in;
      min-height: 11in;
      box-sizing: border-box;
      position: relative;
      font-family: 'Times New Roman', serif;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border: 1px solid rgba(34, 41, 47, 0.125);
      border-radius: 0.428rem;
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      margin-bottom: 1rem;
  }

  @media print {
    .letter {
      border: unset;
      margin-bottom: unset;
      page-break-after: always; /* for older browsers */
      break-after: page; /* modern standard */
    }
  }


  .fold-guide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      /*height: 100%;*/
  }

  .letter-header {
      display: flex;
      justify-content: space-between;
      margin-top: 0.875in;
      margin-left: 0.875in;
      margin-right: 0.875in;
  }

  .letter-logo {
      width: 1.25in;
      height: 1.25in;
      border-radius: 50%;
      object-fit: cover;
      background: #fff;
  }

  .return-address {
      //font-size: 12pt;
      margin: auto 0 auto 0;
      text-align: right;
  }

  .recipient-address {
      position: absolute;
      /*top: 2.5625in;*/
      top: 2.5in;
      left: 0.875in;
      width: 3.625in;
      height: 0.75in;
      //font-size: 12pt;
      line-height: 1.25;
      word-wrap: break-word;
  }

  /*.letter-body {
      position: absolute;
      top: 4in;
      !*margin-top: 2.25in;*!
      margin-left: 0.875in;
      margin-right: 0.875in;
      font-size: 12pt;
      line-height: 1.5;
  }*/

  .letter-body {
    margin: 2in 0.875in 0 0.875in;
    //font-size: 12pt;
    line-height: 1.5;
  }

  .letter-students {
      margin-top: 1rem;
      column-count: 3;
      column-gap: 20px;
  }

  .letter-student {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  div[contenteditable=true]:focus-visible {
      background: #f6f6f6;
      outline: none;
      border: 1px solid #ccc;
      border-radius: 0.357rem;
      padding: 0 4px 0 4px;
  }

  .fold-mark {
      position: absolute;
      width: 100%;
      border-top: 1px dashed #ccc;
  }

  .fold-mark-1 {
      top: 3.67in;
  }

  .fold-mark-2 {
      top: 7.33in;
  }

  .page-start{
    top: 0;
    border-top: 1px dashed red;
  }

  .page-end {
    top: 11in;
    border-top: 1px dashed red;
  }
</style>

<style scoped>
  /* Print-specific styles */
  @media print {
      .fold-mark {
          display: none;
      }
  }
</style>
