// eslint-disable-next-line import/prefer-default-export
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    id
                    name
                }
                application {
                    id
                    instrument {
                        id
                        name
                    }
                    teacher {
                        id
                        name {
                            first
                            last
                        }
                        address {
                            line1
                            line2
                            city
                            state
                            zip
                        }
                        schools {
                            items {
                                id
                                school {
                                    id
                                    name {
                                        legal
                                        popular
                                    }
                                }
                                
                            }
                        }
                    }
                    student {
                        id
                        name {
                            first
                            last
                            preferred
                        }
                        school {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                    }
                }
            }
            nextToken
        }
    }
`;
